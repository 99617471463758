import React, { Component } from 'react';
import {Redirect,Link} from 'react-router-dom'
import {productService} from '../../../actions/productService';
import {connect} from 'react-redux';
import {Form,Button,Alert} from 'react-bootstrap';
import { toast } from 'react-toastify';
class EditProduct extends Component {

  constructor(props){

    super(props);

    this.state={
      processing:false,
      loggedIn:false,
      product:this.props.location.state.prod || null,
      categories:[],
      currencies:[],
      regions:[],
      error:{},
      form:{
        id:0,
        image:null,
        title:null,
        status:1,
        region:0,
        price:null,
        sku:null,
        upc:null,
        category:null,
        currency_id:1,
        content:null

      }
    };

    this.editProd=this.editProd.bind(this);
    //this.formValidate=this.formValidate.bind(this);
    //this.changeCounter=this.changeCounter.bind(this);
  }

  componentDidMount() {
    this.getCategoryRegionCurrency();
  }
  componentWillMount(){
    if(window.token){
        this.setState({loggedIn:true});
      }
  }
  componentDidUpdate(prevProps, prevState) {
    // compare this.props with prevProps in it, and this.state with prevState
    
  }
  

  handleChange(event){
      let name=event.target.name;
      let value=event.target.value;
       let data={};
       data[name]=value;

       this.setState(data);
   
    
  }

  async getCategoryRegionCurrency(){
    let resp=await productService.getCategoryRegionCurrency();
      //console.log("getting out resp==",resp.data.data.category);
       //let data={};
       // data.name='';

    this.setState({
      categories:resp.data.data.category,
      currencies:resp.data.data.currency,
      regions:resp.data.data.region
    });
  }

  async editProd(e){
    e.preventDefault();
    this.setState({error:{}});
    this.state.form.id=this.state.product.id||0;
    const data = new FormData()
    data.append('id',  this.state.form.id)
    if(!this.state.form.id)
    {
      const errs = this.formValidate();
      if(Object.keys(errs).length)
      {
        this.setState({error:errs});
        return;
      }
      data.append('title',  this.state.form.title)
      data.append('status',  this.state.form.status)
      data.append('isCustom',  1)
      data.append('region',  this.state.form.region)
      data.append('min_price',  this.state.form.price)
      data.append('sku',  this.state.form.sku)
      data.append('upc',  this.state.form.upc)
      data.append('category',  this.state.form.category)
      data.append('slug',  this.state.form.category.replace(' ', '-'))
      data.append('currency_id',  this.state.form.currency_id)
      data.append('content',  this.state.form.content)
    } 
    else  data.append('status',  this.state.form.status)
    
    if (this.state.form.image) {
      data.append('image',  this.state.form.image)
    }
    console.log("new data ===",this.state.form);
    //return;
    let resp=await productService.editProduct(data);
    if(resp.data.status==200)
    {
      this.state.product.id?toast('Update successful ....'):toast('Added successfully ....');
      this.props.history.goBack()
    }
    console.log(data,"getting out resp==",resp);
     
  }

  formValidate (){
    let err = {}; console.log("it isss",this.state.title)
    if (!this.state.form.title) err.title = 'Title is required'
    if (!this.state.form.price) err.price = 'Price is required'
    if (!this.state.form.sku) err.sku = 'SKU is required'
    if (!this.state.form.upc) err.upc = 'UPC is required'
    if (!this.state.form.category) err.category = 'Category is required'
    return err
  }


  render() {

    if(this.state.loggedIn==false){
      console.log("redirected");
      return <Redirect to='/' />
    }

    return (
      <>
        <div className="container-fluid hm-white-bg">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="ddsh-space-padd cutome-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#" onClick={() => this.props.history.goBack()}>Vouchers</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Add Version</li>
                  </ol>
                </nav>
              </div>	
            </div>
            <div className="col-sm-12 col-lg-12">
              {/* <div className="ddsh-space-padd">
                <div className="ddsh-heading-tp">
                <button onClick={()=>this.props.dispatch(toggleDispatch('INCREMENT',5))}>+</button>
                <button onClick={()=> this.props.dispatch(toggleDispatch('DECREMENT',5))}>-</button>
                <h1>Welcome to Category { this.props.counter}</h1>
                </div>
              </div>	 */}
              </div>
              
          </div>
         
          
        <div className='mt-5'>     
              <Form onSubmit={this.editProd}>
              <div className="row mt-1 justify-content-md-center">
                  <div className="col-sm-10 col-lg-5" >
                        <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                            <Form.Label  className="mb-2 mr-2 form-label"><b>Category</b></Form.Label>
                  
                            <input  className="form-control" list="datalist" value={this.state.product.category} onChange={e=>this.state.form.category=e.target.value}  />

                            <datalist id="datalist"  size="45">
                              { this.state.categories.map( function (key, index){
                              
                                return(<option key={key} value={key}/>)
                              }, this)}
                            </datalist>
                        </Form.Group>
                      
                  </div>
                  <div className="col-sm-10 col-lg-5" hidden={this.props.match.params.id?true:false}>
                      <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                          <Form.Label className="mb-2 mr-2 form-label"><b>Currency</b></Form.Label>
                          <Form.Select onChange={e=>this.state.form.currency_id=e.target.value} className="form-control select-form" value={this.state.product.currency_id} disabled={this.props.match.params.id?"disabled":""}>
                                { 
                                  this.state.currencies.map(function (key, index) {
                                    return (
                                      <option key={index} value={key.id}>{key.name}</option> );
                                  }, this)
                                }
                            </Form.Select>
                          
                      </Form.Group>
                  </div>    
            

        
                <div className="col-sm-10 col-lg-5" >
                      <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                          <Form.Label  className="mb-2 mr-2 form-label"><b>Title</b></Form.Label>
                          <Form.Control className="form-control" type="text" value={this.state.product.title} onChange={e=>this.state.form.title=e.target.value} disabled={this.props.match.params.id?"disabled":""}/>
                      
                      </Form.Group>
                  </div>
                  <div className="col-sm-10 col-lg-5">
                      <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                          <Form.Label className="mb-2 mr-2 form-label"><b>Price</b></Form.Label>
                          <Form.Control className="form-control" type="number" step="0.01" value={this.state.product.min_price} onChange={e=>this.state.form.price=e.target.value} disabled={this.props.match.params.id?"disabled":""} />
                          
                      </Form.Group>
                  </div>
           
                <div className="col-sm-10 col-lg-5">    
                    <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                        {/* readOnly */}
                        <Form.Label className="mb-2 mr-2 form-label"><b>SKU</b> </Form.Label>
                        <Form.Control className="form-control" type="number" value={this.state.product.sku} onChange={e=>this.state.form.sku=e.target.value} disabled={this.props.match.params.id?"disabled":""} />
                        
                    </Form.Group>
                </div>
                <div className="col-sm-10 col-lg-5">        
                    <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                        <Form.Label className="mb-2 mr-2 form-label"><b>UPC</b></Form.Label>
                        <Form.Control className="form-control" type="text" value={this.state.product.upc} onChange={e=>this.state.form.upc=e.target.value} disabled={this.props.match.params.id?"disabled":""} />
                        
                    </Form.Group>
                </div>
         
           
                <div className="col-sm-10 col-lg-5">     
                    <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                        <Form.Label className="mb-2 mr-2 form-label"><b>Status</b></Form.Label>
                        <Form.Select onChange={e=>this.state.form.status=e.target.value} className="form-control select-form">
                            <option key={1} value='1'>Active</option>
                            <option key={2} value='0'>In Active</option>
                        </Form.Select>
                        
                    </Form.Group>
                </div> 
                <div className="col-sm-10 col-lg-5">    
                    <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                        <Form.Label className="mb-2 mr-2 form-label"><b>Image</b></Form.Label>
                        <Form.Control onChange={e=>this.state.form.image=e.target.files[0]} type="file" />
                        
                    </Form.Group>
                </div>
                
           
          
                <div className="col-sm-10 col-lg-5" hidden={this.props.match.params.id?true:false}>    
                      <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                          <Form.Label className="mb-2 mr-2 form-label"><b>Region</b></Form.Label>
                          <Form.Select onChange={e=>this.state.form.region=e.target.value} className="form-control select-form" value={this.state.product.region} disabled={this.props.match.params.id?"disabled":""}>
                              <option value='0'>Global</option>
                              { 
                                this.state.regions.map(function (key, index) {
                                  return (
                                    <option key={index} value={key.id}>{key.name}</option> );
                                }, this)
                              }
                              
                    
                          </Form.Select>
                          
                      </Form.Group>
                  </div>  
                  <div className="col-sm-10 col-lg-5"  hidden={this.props.match.params.id?true:false}>    
                      <Form.Group className="m-3 d-flex form-group" controlId="formBasicEmail">
                          <Form.Label className="mb-2 mr-2 form-label"><b>Policy</b></Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Redemption, Terms and Conditions, Product Description"
                            style={{ height: '100px' }}
                            onChange={e=>this.state.form.content=e.target.value}
                          />
                          
                      </Form.Group>
                  </div>
              </div>
              <div className="col-sm-12 col-lg-12"> 
                 { 
                  Object.keys(this.state.error).map((variant, idx) => (
                      <Alert key={idx} variant='danger'>
                        {this.state.error[variant]}
                      </Alert>
                      ), this)
                  } 
              </div>    
                <div className="col-sm-12 col-lg-12 p-4">    
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </div>    
                </Form>
        </div>   
        </div>
      </>
      
    );
  }


}
const mapStateToProps = state => ({
    counter: state.counter
});
const mapDispatchToProps = (dispatch) => ({ 
    
    increment:dispatch(toggleDispatch('INCREMENT',5)), 
    decrement:dispatch(toggleDispatch('DECREMENT',1))
});

function toggleDispatch(ACTION_NAME,PAYLOAD=null) {
    return { type: ACTION_NAME, payload: PAYLOAD }
}

export default connect(
    mapStateToProps
)(EditProduct);
