import React, { Component, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthService } from '../actions/authService';
import { Route, Switch, useHistory, Redirect, useLocation, BrowserRouter as Router, Link } from "react-router-dom";

function Login(props) {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const initialState = {
        loading: false,
        form: { email: null, password: null },
        error: null
    };
    const [value, setValue] = useState(initialState);
    const [form, setForm] = useState(initialState);
    const history = useHistory();
    const handleSubmit = async (e) => {
        //addChoreLog([choreDesc, name, date])
        e.preventDefault();
        //dispatch(AuthService.processing());
        dispatch(AuthService.logOut());
        console.log(value, "==== Form iss ===", form)
        let res = await AuthService.login(form.form);
        console.log(res)
        if (res.data.status == 200) {
            dispatch(AuthService.loginResp(res.data.data));
            history.push("/dashboard");
        }
        else
            toast("Error in credentials..!");
        console.log("new state ==", auth);
    }

    useEffect(async () => {
        if (auth.isLogged) {
            history.push("/dashboard");
        }
    }, [auth.isLogged]);

    return (
        <>
            <div className="wrapper-login-full">
                <div className="wrapper-login-middle">
                    <div className="container-fluid">
                        <ToastContainer />
                        <div className="row">
                            <div className="col-sm-12 col-xl-6 d-none d-xl-block padd-offleftright">
                                <div className="login-bg-left">
                                    <div className="cn-login-logo">
                                        <span className="cn_item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="71" height="70" fill="#fff" viewBox="0 0 71 70"> 
                                                <path d="M42.025,55.845v6.948H28.974V28.565h5.743V21.36H21.667V41.433H7.31V28.565h7.047V21.36H6.787A6.749,6.749,0,0,0,0,28.051v13.9a6.748,6.748,0,0,0,6.787,6.691h14.88v14.67A6.746,6.746,0,0,0,28.452,70H42.547a6.746,6.746,0,0,0,6.786-6.691V55.845H42.025ZM64.211,21.36H49.332V6.691A6.745,6.745,0,0,0,42.547,0H28.452a6.745,6.745,0,0,0-6.786,6.69v7.463h7.307V7.206H42.025V41.433H36.283v7.205h13.05V28.565H63.689V41.433H56.642v7.205h7.569A6.748,6.748,0,0,0,71,41.947v-13.9A6.749,6.749,0,0,0,64.211,21.36Z" />
                                            </svg> 
                                        </span>
                                        <div className="cmp-title">
                                            <b>Ropay</b><br />
                                            <span>Admin Panel</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-xl-6 padd-offleftright">
                                <div className="login-tp-btn d-none d-xl-block">
                                    <div className="d-flex align-items-center">
                                        {/* <div>Don't have a DME account yet?</div>
                                        <div><a href="" className="btn btn-empty">Sign Up</a></div> */}
                                    </div>
                                </div>
                                <div className="login-wraper">
                                    <div className="form-signin-outer">
                                        <div className="login-logo d-xl-none">
                                            <img src="/img/login-logo.png" alt="" />
                                        </div>
                                        <div className="login-texttitle">
                                            <h1>Welcome to Ropay</h1>
                                            <span>A Reliable Voucher Shop</span>
                                        </div>
                                        <form className="form-signin">
                                            <div className="row">
                                                <div className="col-sm-12 fg-float form-label-group login-label"> 
                                                    <input type="text" className="form-control" placeholder="Email Address" onChange={e => setForm(prevState => ({ form: { ...prevState.form, email: e.target.value } }))} />
                                                </div>
                                                <div className="col-sm-12 fg-float form-label-group login-label">
                                                    <input type="password" className="form-control" placeholder="Password" onChange={e => setForm(prevState => ({ form: { ...prevState.form, password: e.target.value } }))} />
                                                    {/* onChange={e => setForm(prevState => ({ form: {  ...prevState.form,  password: e.target.value } }) ) } */}
                                                </div>
                                                <div className="col-sm-12 fg-float form-label-group login-label">
                                                    <div className="rb-roboto">
                                                        {/* <img src="img/robotimg.jpg" alt="" className="mx-auto d-block" /> */}
                                                    </div>

                                                </div>
                                                <div className="form-label-group col-sm-12 btn-custome">
                                                    <button className="btn btn-lg btn-block btn-primary" onClick={e => { handleSubmit(e) }} type="submit">Continue </button>
                                                    {/* <a href="" className="forget-linkbtn">Forgot Password?</a> */}
                                                </div>
                                                <div className="form-label-group col-sm-12 term-notebtn">
                                                    <span>By selecting ‘Continue’, you agree to iWare <a href="">Terms of Service</a> and <a href="">Privacy Policy.</a></span>
                                                </div> 
                                            </div> 
                                        </form>
                                        <div className="login-tp-btn d-xl-none">
                                            <div className="d-flex align-items-center">
                                                {/* <div>Don't have a DME account yet?</div>
                                                <div><a href="" className="btn btn-empty">Sign Up</a></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;