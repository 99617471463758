import axios from "../helpers/axios";
import { useSelector, useDispatch } from 'react-redux';
//const dispatch = useDispatch();
export const productService = {

    getProducts: async (payload) => {
        //console.log("get products...");
        let resp = await axios.get(`/getProducts`);
        return resp;
    },
    getCategoryRegionCurrency: async (payload) => {
        let resp = await axios.get(`/getCategoryRegionCurrency`);
        return resp;
    },
    getVariants: async (payload) => {
        let resp = await axios.get(`/productVariant/${payload.slug}`);
        return resp;
    },
    editProduct: async (payload) => {
        console.log("get products...", payload);
        let resp = await axios.post(`/editProduct`, payload, {});
        console.log("single response ===", resp);
        return resp;
    },
    processing: (payload = null) => {
        return {
            type: 'LOADING',
            payload: payload
        }
    },

    loginResp: (payload) => {
        return {
            type: 'LOGIN',
            payload: payload
        }
    },

    logOut: (payload = null) => {
        return {
            type: 'LOGOUT',
            payload: payload
        }
    },

}
// export const login = async (payload) => {
//     console.log("hiiiiiii")
//     //const dispatch = useDispatch();
//     //dispatch(processing());

//     let resp= await  axios
//       .get(`http://localhost:8000/api/login`, payload);

//     return resp;  
//     //   .then(res => {
//     //     console.log("Api Response is coming isss ===",res);  
//     //     return res;
//     //   //  dispatch(loginResp(res.data));
//     //   })
//     //   .catch(err => {
//     //     console.log("Error ===",err);  
//     //     return err;
//     //     //dispatch(errorResp(err.message));
//     //   });

// };

// export const processing = (payload=null) =>{
//     return {
//         type:'LOADING',
//         payload:payload
//     }
// }

// export const loginResp = (payload) =>{
//     return {
//         type:'LOGIN',
//         payload:payload
//     }
// }

// export const errorResp = (payload) =>{
//     return {
//         type:'ERROR',
//         payload:payload
//     }
// }