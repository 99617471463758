import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import { increment, decrement } from "../actions";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
function Header() {
  return (
    <>
      <div id="top-search-wrap">
        <div className="center-searchtop">
          <input
            className="form-control"
            type="text"
            placeholder="Search here..."
          />
          <button>
            <span className="cn_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 12 12"
                style={{ fill: "#3e3e3e" }}
              >
                <path
                  id="Color_Fill_1"
                  data-name="Color Fill 1"
                  d="M9.844,4.922A4.9,4.9,0,0,1,8.706,8.065L11.861,11.2a0.469,0.469,0,1,1-.661.665L8.041,8.726a4.92,4.92,0,0,1-5.529.489A0.469,0.469,0,0,1,2.972,8.4a3.982,3.982,0,1,0-1.514-1.5,0.469,0.469,0,0,1-.814.464A4.922,4.922,0,1,1,9.844,4.922Z"
                ></path>
              </svg>
            </span>
          </button>
          <div className="clse-tpsearch">
            <span className="cn_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 64 63"
                width="20"
                height="20"
                style={{ fill: "#1d79ed" }}
              >
                {" "}
                <g id="Close">
                  {" "}
                  <path
                    id="Cross"
                    d="M48.160,19.851 C49.276,18.735 49.276,16.924 48.160,15.807 C47.043,14.691 45.232,14.691 44.116,15.807 C31.983,27.940 31.983,27.940 31.983,27.940 C31.983,27.940 19.851,15.807 19.851,15.807 C18.734,14.690 16.924,14.690 15.807,15.807 C14.691,16.924 14.691,18.735 15.807,19.851 C27.939,31.983 27.939,31.983 27.939,31.983 C27.939,31.983 15.807,44.115 15.807,44.115 C14.691,45.232 14.691,47.043 15.807,48.159 C16.924,49.276 18.734,49.276 19.851,48.159 C31.983,36.027 31.983,36.027 31.983,36.027 C31.983,36.027 44.116,48.159 44.116,48.159 C45.232,49.276 47.043,49.276 48.160,48.159 C49.276,47.043 49.276,45.232 48.160,44.115 C36.028,31.983 36.028,31.983 36.028,31.983 C36.028,31.983 48.160,19.851 48.160,19.851 z"
                  ></path>{" "}
                </g>{" "}
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div id="sidebar-tab">
        <div className="menu-logo">
          <a href="#">
            <span className="cn_item">
              <Link  to="/dashboard/">
                <img className="img-responsive" src="/img/RoPay-Footer.svg" width="100" height="100" alt="" /> 
              </Link>
            </span>
          </a>
        </div>
        <ul className="nav flex-column">
          <li className="nav-item"> 
            <Link className="nav-link active" to="/dashboard/">
              <span className="cn_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    id="Color_Fill_4"
                    data-name="Color Fill 4"
                    fill="#3e3e3e"
                    d="M13.906,0a3.129,3.129,0,0,0-3.125,3.125V6.094a3.129,3.129,0,0,0,3.125,3.125h2.969A3.129,3.129,0,0,0,20,6.094V3.125A3.129,3.129,0,0,0,16.875,0H13.906Zm2.969,7.656H13.906a1.564,1.564,0,0,1-1.562-1.563V3.125a1.564,1.564,0,0,1,1.562-1.562h2.969a1.564,1.564,0,0,1,1.563,1.562V6.094A1.564,1.564,0,0,1,16.875,7.656ZM3.125,0A3.129,3.129,0,0,0,0,3.125V6.094A3.129,3.129,0,0,0,3.125,9.219H6.093A3.129,3.129,0,0,0,9.219,6.094V3.125A3.129,3.129,0,0,0,6.093,0H3.125ZM6.093,7.656H3.125A1.564,1.564,0,0,1,1.562,6.094V3.125A1.564,1.564,0,0,1,3.125,1.562H6.093A1.564,1.564,0,0,1,7.656,3.125V6.094A1.564,1.564,0,0,1,6.093,7.656ZM3.125,10.781A3.129,3.129,0,0,0,0,13.906v2.969A3.129,3.129,0,0,0,3.125,20H6.093a3.129,3.129,0,0,0,3.125-3.125V13.906a3.129,3.129,0,0,0-3.125-3.125H3.125Zm2.969,7.656H3.125a1.564,1.564,0,0,1-1.562-1.562V13.906a1.564,1.564,0,0,1,1.562-1.563H6.093a1.564,1.564,0,0,1,1.563,1.563v2.969A1.564,1.564,0,0,1,6.093,18.438Zm12.344-1.562a1.564,1.564,0,0,1-1.562,1.563H13.906a1.564,1.564,0,0,1-1.562-1.562V13.906a1.564,1.564,0,0,1,1.562-1.563h2.969a1.564,1.564,0,0,1,1.433.937,0.781,0.781,0,1,0,1.432-.626,3.126,3.126,0,0,0-2.864-1.874H13.906a3.129,3.129,0,0,0-3.125,3.125v2.969A3.129,3.129,0,0,0,13.906,20h2.969A3.129,3.129,0,0,0,20,16.875,0.781,0.781,0,0,0,18.438,16.875Z"
                  />
                </svg>
              </span>
              <span className="nav-title">Dashboard</span>
              <span className="dotmenu"></span>
            </Link> 
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard/product">
              <span className="cn_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#3e3e3e"
                    d="M18.414,12.7a9.366,9.366,0,0,0-2.389-1.5,0.781,0.781,0,1,0-.635,1.428,7.814,7.814,0,0,1,1.993,1.241,3.116,3.116,0,0,1,1.055,2.341v1.445a0.782,0.782,0,0,1-.781.781H2.344a0.782,0.782,0,0,1-.781-0.781V16.211a3.116,3.116,0,0,1,1.055-2.341c0.789-.694,3.089-2.307,7.382-2.307a5.788,5.788,0,1,0-3.521-1.2A10.862,10.862,0,0,0,1.586,12.7,4.678,4.678,0,0,0,0,16.211v1.445A2.346,2.346,0,0,0,2.344,20H17.656A2.346,2.346,0,0,0,20,17.656V16.211A4.678,4.678,0,0,0,18.414,12.7ZM5.781,5.781A4.219,4.219,0,1,1,10,10,4.224,4.224,0,0,1,5.781,5.781Z"
                  />
                </svg>
              </span>
              <span className="nav-title">Products</span>
              <span className="dotmenu"></span>
            </Link>
          </li>
          
        </ul>
      </div>
    </>
  );
}

export default Header;
