/* eslint-disable default-case */
import axios from "../helpers/axios";

const initialState = {
    loading: false,
    user: null,
    rememberMe: null,
    isLogged: false,
    error: null
};


const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return !state;
        case 'LOGIN':
            console.log("in login reducer", action.payload)
            const { token, user } = action.payload;
            if (!action.payload.exists) {
                localStorage.setItem('rememberMe', token);
                localStorage.setItem('user', JSON.stringify(user));
                window.token = token;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            }

            return {
                ...state,
                user: user,
                rememberMe: token, // after update user formsubmition reset
                isLogged: true
            };
        case 'LOGOUT':
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('user');
            axios.defaults.headers.common['Authorization'] = 'Bearer '
            window.token = null;
            return {
                ...state,
                user: null,
                rememberMe: null, // after update user formsubmition reset
                isLogged: false
            };
        case 'LOADING':
            return {
                ...state,
                loading: !state.loading
            };
        case 'ERROR':
            return {
                ...state,
                error: !action.payload
            };
        default:
            return state;

    }
};
export default authReducer;