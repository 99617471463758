/* eslint-disable default-case */
const counterReducer = (state = 0, action) => {
    switch (action.type) {
        case 'INCREMENT':
            console.log("+ PAyloadddd", action.payload)
            return state + action.payload; //+ 1
        case "DECREMENT":
            console.log("- PAyloadddd", action.payload)
            return state - action.payload;
        default:
            return state;
    }
};
export default counterReducer;