import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { productService } from "../../../actions/productService";
import { connect } from "react-redux";
import { Table, Button } from "react-bootstrap";
import ReactLoading from "react-loading";

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      loggedIn: false,
      products: [],
    };

    //this.categoryLength=this.categoryLength.bind(this);
    //this.submit=this.submit.bind(this);
    //this.changeCounter=this.changeCounter.bind(this);
  }

  componentDidMount() {
    this.getProducts();
    console.log("Component did mount called");
  }
  componentWillMount() {
    if (window.token) {
      this.setState({ loggedIn: true });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let data = {};
    data[name] = value;

    this.setState(data);
  }

  async getProducts() {
    let resp = await productService.getProducts();
    console.log("getting out resp==", resp.data.data);
    //let data={};
    // data.name='';

    this.setState({ products: resp.data.data });
  }

  categoryLength(key) {
    console.log("getting out resp==", this.state.products[key].length);
  }
  render() {
    if (this.state.loggedIn == false) {
      console.log("redirected");
      return <Redirect to="/" />;
    }

    return (
      <>
        <div className="container-fluid hm-white-bg">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="ddsh-space-padd cutome-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0)">Vouchers</a>
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">
                      Product
                    </li> */}
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12">
              <div className="ddsh-space-padd">
                {/* <div className="ddsh-heading-tp">
                <button onClick={()=>this.props.dispatch(toggleDispatch('INCREMENT',5))}>+</button>
                <button onClick={()=> this.props.dispatch(toggleDispatch('DECREMENT',5))}>-</button>
                <h1>Welcome to Category { this.props.counter}</h1>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <Link
                to={{
                  pathname: `/dashboard/productDetail`,
                  state: { prod: {} },
                }}
                className="float-right p-4"
              >
                <Button>
                  <p>Add Version</p>
                </Button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category</th>
                    <th>Versions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(this.state.products).length ? (
                    Object.keys(this.state.products).map(function (key, index) {
                      return (
                        <tr key={key}>
                          <td>{index + 1}</td>
                          <td>{key}</td>
                          <td>{this.state.products[key].length}</td>
                          <td>
                            {" "}
                            <Link
                              to={{
                                pathname: `/dashboard/productVer/${this.state.products[key][0].slug}`,
                                state: {
                                  prodVersions: this.state.products[key],
                                },
                              }}
                            >
                              <Button>
                                <p>View Versions!</p>
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      );
                    }, this)
                  ) : (
                    <ReactLoading
                      type={"spin"}
                      color={"#ffaab"}
                      height={"20%"}
                      width={"20%"}
                    />
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  counter: state.counter,
});
const mapDispatchToProps = (dispatch) => ({
  increment: dispatch(toggleDispatch("INCREMENT", 5)),
  decrement: dispatch(toggleDispatch("DECREMENT", 1)),
});

function toggleDispatch(ACTION_NAME, PAYLOAD = null) {
  return { type: ACTION_NAME, payload: PAYLOAD };
}

export default connect(mapStateToProps)(Product);
