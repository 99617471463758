
import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './layout/Header';
import MainNav from './layout/MainNav';
import Home from './layout/Home';
import Product from './layout/components/products/Product';
import Version from './layout/components/products/Version';
import EditProduct from './layout/components/products/EditProduct';
import { AuthService } from './actions/authService';
import Login from './layout/Login';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useLocation, BrowserRouter, Link } from "react-router-dom";
function App() {
  const initialState = {
    isLogged: false,
  };
  const counter = useSelector(state => state.counter);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(async () => {
    // Good!
    let resp = await AuthService.isAuthenticated();

    if (resp) {
      dispatch(AuthService.loginResp(resp));
    }
    console.log(resp, "auth value iss", auth.isLogged)
  }, []);

  return (
    <BrowserRouter>

      <div className="App">

        <Switch>
          <Route path='/' exact component={Login}></Route>
          <Route path='/dashboard' render={() =>
            <>
              <Header />
              <MainNav />
              <ToastContainer />
              <section id="main-section">
                <Route path='/dashboard' exact component={Home}></Route>
                <Route path='/dashboard/product' component={Product}></Route>
                <Route path='/dashboard/productVer/:slug' component={Version}></Route>
                <Route path='/dashboard/productDetail/:id?' component={EditProduct}></Route>

                {/* <Route path='/login' component={Login}></Route> */}
              </section>

            </>
          } />
          <Route path='*' component={() => "404 not found"}></Route>
        </Switch>
      </div>

    </BrowserRouter>

  );
}

export default App;
