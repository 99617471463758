import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { productService } from '../../../actions/productService';
import { connect } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
var config = require('../../../config/repository.default.json');

class Version extends Component {

  constructor(props) {

    super(props);

    this.state = {
      processing: false,
      loggedIn: false,
      productVer: []
    };

    //this.categoryLength=this.categoryLength.bind(this);
    // this.submit=this.submit.bind(this);
    //this.changeCounter=this.changeCounter.bind(this);
  }

  componentDidMount() {
    this.getVariants();
    console.log("Component did mount called", this.state.productVer);
  }
  componentWillMount() {
    if (window.token) {

      this.setState({ loggedIn: true });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let data = {};
    data[name] = value;

    this.setState(data);
  }

  async getVariants() {

    let resp = await productService.getVariants({ slug: this.props.match.params.slug });
    console.log("getting out resp==", resp.data.data);
    //let data={};
    // data.name='';

    this.setState({ productVer: resp.data.data });
  }

  categoryLength(key) {

    console.log("getting out resp==", this.state.products[key].length);

  }
  render() {

    if (this.state.loggedIn == false) {
      console.log("redirected");
      return <Redirect to='/' />
    }

    return (
      <>
        <div className="container-fluid hm-white-bg">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="ddsh-space-padd cutome-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#" onClick={() => this.props.history.goBack()}>Vouchers</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Version
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>Image</th>
                    <th>Global</th>
                    <th>Custom</th>
                    <th>Status</th>
                    <th>SKU</th>
                    <th>UPC</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.productVer.map(function (key, index) {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{key.title}</td>
                        <td>{key.category}</td>
                        <td>
                          {key.currency.symbol} {key.min_price}
                        </td>
                        <td><img src={key.image && key.image.includes('http') ? key.image : (config.base_domain + '/storage/' + key.image)} width='100' height='100' alt={key.title} /></td>
                        <td>{key.is_global ? "True" : "False"}</td>
                        <td>{key.isCustom ? "True" : "False"}</td>
                        <td>{key.status ? "Active" : "in Active"}</td>
                        <td>{key.sku}</td>
                        <td>{key.upc}</td>
                        <td>
                          <Link to={{
                              pathname: `/dashboard/productDetail/${this.state.productVer[index].id}`,
                              state: { prod: this.state.productVer[index] }
                            }}>
                          <Button>
                            <p>Details!</p>
                          </Button>
                        </Link></td>
                      </tr>
                    );
                  }, this)}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  }


}
const mapStateToProps = state => ({
  counter: state.counter
});
const mapDispatchToProps = (dispatch) => ({

  increment: dispatch(toggleDispatch('INCREMENT', 5)),
  decrement: dispatch(toggleDispatch('DECREMENT', 1))
});

function toggleDispatch(ACTION_NAME, PAYLOAD = null) {
  return { type: ACTION_NAME, payload: PAYLOAD }
}

export default connect(
  mapStateToProps
)(Version);
