import React, { Component } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increment, decrement } from '../actions';
import { Route, Switch, Redirect, useHistory, useLocation, BrowserRouter as Router, Link } from "react-router-dom";
function MainNav() {
    const dispatch = useDispatch();
    const history = useHistory();
    function toggleDispatch(ACTION_NAME, PAYLOAD = null) {
        return { type: ACTION_NAME, payload: PAYLOAD }
    }
    function handleLogout() {
        dispatch(toggleDispatch('LOGOUT'));
        history.push("/");
    }
    return (
        <>
            <header id="header">
                <div className="main-head-top">
                    <div className="menu-trigger">
                        <div className="line-wrap">
                            <div className="line top"></div>
                            <div className="line center"></div>
                            <div className="line bottom"></div>
                        </div>
                        <div className="close-close-line">
                            <span className="cn_item">
                                <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 64 63" width="20" height="20" style={{ fill: '#1d79ed' }}> <g id="Close"> <path id="Cross" d="M48.160,19.851 C49.276,18.735 49.276,16.924 48.160,15.807 C47.043,14.691 45.232,14.691 44.116,15.807 C31.983,27.940 31.983,27.940 31.983,27.940 C31.983,27.940 19.851,15.807 19.851,15.807 C18.734,14.690 16.924,14.690 15.807,15.807 C14.691,16.924 14.691,18.735 15.807,19.851 C27.939,31.983 27.939,31.983 27.939,31.983 C27.939,31.983 15.807,44.115 15.807,44.115 C14.691,45.232 14.691,47.043 15.807,48.159 C16.924,49.276 18.734,49.276 19.851,48.159 C31.983,36.027 31.983,36.027 31.983,36.027 C31.983,36.027 44.116,48.159 44.116,48.159 C45.232,49.276 47.043,49.276 48.160,48.159 C49.276,47.043 49.276,45.232 48.160,44.115 C36.028,31.983 36.028,31.983 36.028,31.983 C36.028,31.983 48.160,19.851 48.160,19.851 z"></path> </g> </svg>
                            </span>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-md"> 
                        <ul className="navbar-nav navbar-theme-top ml-auto"> 
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="thumb-welc"><img className="img-responsive" src="/img/default-welcm-img.png" alt="" /></span>
                                    <span className="usernm-tp">Admin</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01">
                                    <a className="dropdown-item" href="#" onClick={() => { handleLogout() }}>logout</a> 
                                </div>
                            </li> 
                        </ul>
                    </nav>
                </div> 
            </header>
        </>
    );
}

export default MainNav;