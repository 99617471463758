import axios from "../helpers/axios";
import { useSelector, useDispatch } from 'react-redux';
//const dispatch = useDispatch();
export const AuthService = {

    login: async (payload) => {
        console.log("hiiiiiii", payload)
        //const dispatch = useDispatch();
        //dispatch(processing());

        let resp = await axios
            .post(`/login`, payload);

        return resp;
    },

    isAuthenticated: async (payload) => {

        let rememberMe = localStorage.getItem('rememberMe') || null;
        let user = JSON.parse(localStorage.getItem('user')) || null;
        if (rememberMe && user) {
            console.log("in authenticated...");
            let resp = await axios.get(`/me`);
            console.log("me response ===", resp);
            return { 'rememberMe': rememberMe, 'user': user, 'exists': 1 };
        }


        return null;
        //const dispatch = useDispatch();
        //dispatch(processing());

        // let resp= await  axios
        //   .post(`http://localhost:8000/api/login`, payload);

        //return resp;  
    },

    processing: (payload = null) => {
        return {
            type: 'LOADING',
            payload: payload
        }
    },

    loginResp: (payload) => {
        return {
            type: 'LOGIN',
            payload: payload
        }
    },

    logOut: (payload = null) => {
        return {
            type: 'LOGOUT',
            payload: payload
        }
    },

    errorResp: (payload) => {
        return {
            type: 'ERROR',
            payload: payload
        }
    }
}
// export const login = async (payload) => {
//     console.log("hiiiiiii")
//     //const dispatch = useDispatch();
//     //dispatch(processing());

//     let resp= await  axios
//       .get(`http://localhost:8000/api/login`, payload);

//     return resp;  
//     //   .then(res => {
//     //     console.log("Api Response is coming isss ===",res);  
//     //     return res;
//     //   //  dispatch(loginResp(res.data));
//     //   })
//     //   .catch(err => {
//     //     console.log("Error ===",err);  
//     //     return err;
//     //     //dispatch(errorResp(err.message));
//     //   });

// };

// export const processing = (payload=null) =>{
//     return {
//         type:'LOADING',
//         payload:payload
//     }
// }

// export const loginResp = (payload) =>{
//     return {
//         type:'LOGIN',
//         payload:payload
//     }
// }

// export const errorResp = (payload) =>{
//     return {
//         type:'ERROR',
//         payload:payload
//     }
// }