import React, { Component } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increment, decrement } from '../actions';
import { Route, Switch, Redirect, useLocation, BrowserRouter as Router, Link } from "react-router-dom";
import { connect } from 'react-redux';
// var Diamond = require("../assets/img/hm-nodataimage.png");
import Diamond from ".././assets/img/hm-nodataimage.png"

class Home extends Component {

  constructor(props) {

    super(props);

    this.state = {

      loggedIn: true,
    };
 
  }

  componentDidMount() {
    console.log('token', window.token);
    if (!window.token) {

      this.setState({ loggedIn: false });
    }
  }
  componentWillMount() {

  }


  render() {

    if (this.state.loggedIn == false) {
      console.log("redirected");
      return <Redirect to='/' />
    }

    return (
      <>
        <div className="container-fluid hm-white-bg">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="ddsh-space-padd cutome-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                    {/* <li className="breadcrumb-item active" aria-current="page">Library</li> */}
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12">
              <div className="ddsh-space-padd">
                <div className="ddsh-heading-tp">
                  <h1>Welcome Admin!</h1>
                </div>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="nocenter">
              <div className="nodatashow">
                <img src={Diamond} className="img-fluid" alt="" /> 
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }

  submit(e) {
    //this.setState({loggedIn:true})
    e.preventDefault();

    // window.axios.post('http://passport.test/api/login',{email:this.state.email,password:this.state.password})
    // .then(response=>{
    //    console.log(response);

    //    this.setState({loggedIn:true});

    //    localStorage.setItem('token',response.data.auth.access_token)
    // });
  }
}
const mapStateToProps = state => ({
  counter: state.counter
});
const mapDispatchToProps = (dispatch) => ({

  increment: dispatch(toggleDispatch('INCREMENT', 5)),
  decrement: dispatch(toggleDispatch('DECREMENT', 1))
});

function toggleDispatch(ACTION_NAME, PAYLOAD = null) {
  return { type: ACTION_NAME, payload: PAYLOAD }
}

export default connect(
  mapStateToProps
)(Home);

