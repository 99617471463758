// First we need to import axios.js
import axios from 'axios';
var config = require('../../src/config/repository.default.json');
// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    baseURL: config.base_domain+config.version,
    //withCredentials: true,
    headers: { Accept: 'application/json' }
});

// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.withCredentials = true
instance.defaults.headers.common['Authorization'] ='Bearer ' + localStorage.getItem('rememberMe');
// "Access-Control-Allow-Origin": "*",
// "Access-Control-Allow-Credentials": true,
// Also add/ configure interceptors && all the other cool stuff

//instance.interceptors.request...
// axios.interceptors.request.use(request => {
//     console.log(request);
//     // Edit request config
//     return request;
// }, error => {
//     console.log(error);
//     return Promise.reject(error);
// });

export default instance;